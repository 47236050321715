import React, { FunctionComponent } from 'react';
import FaqsIcon from "../../assets/images/faqs.png";
import Faqs from '.';
import Section from '../Section';
import Heading from '../Heading';
import Button from '../Button/Button';
import useTranslations from '../useTranslations';
import LocalizedLink from '../LocalizedLink';

type FaqsSectionProps = {
    items?: any[],
    faqs?: any
};

const FaqsSection: FunctionComponent<FaqsSectionProps> = ({ faqs, items, ...props }) => {
    if (!faqs) {
        const { faqs: DefaultFaqs } = useTranslations()
        faqs = DefaultFaqs
    }

    if (items) {
        faqs.items = items
    }

    console.log(faqs.items)

    return (
        <>
            <Section className={'py-20'}>
                <div className={'flex mb-16 flex-wrap justify-center'}>
                    <div className={'w-full lg:w-2/5 flex justify-center items-start lg:order-last mb-10 lg:mb-0'}>
                        <img src={FaqsIcon} alt="" className={'w-full max-w-lg'} />
                    </div>
                    <div className={'w-full lg:w-3/5'}>
                        <Heading type='h3' className={'text-ink-40 pb-3.5'}>{faqs.subTitle}</Heading>
                        <Heading type='h2'>{faqs.title}</Heading>
                        <div className={'py-12 sm:py-16'}>
                            <Faqs items={faqs.items} />
                        </div>
                        <LocalizedLink to={'/faqs'}>
                            <Button variant="border">{faqs.all}</Button>
                        </LocalizedLink>
                    </div>
                </div>
            </Section>
        </>
    );
};

export default FaqsSection;
