import React, { FunctionComponent, useEffect, useState } from 'react';
import Section from "../components/Section";
import Button from "../components/Button/Button";
import Heading from "../components/Heading";
import FeaturedPostsSection from "../sections/FeaturedPostsSection";
import HeroVideo from "../components/HeroVideo";
import useTranslations from '../components/useTranslations';
import FaqsSection from '../components/Faqs/FaqsSection';
import PromoSection from '../components/PromoSection';
import CostsImage from "../assets/images/costs.png";
import LocalizedLink from '../components/LocalizedLink';
import { graphql } from 'gatsby';

type SroProps = {
    data?: any
};

const SroVideoFold: FunctionComponent<SroProps> = (props) => {
    const { pages: { sro } } = useTranslations()

    const [featuredPosts, setFeaturedPosts] = useState<any>([])
    const [faqs, setFaqs] = useState<any>([])

    useEffect(() => {
        if (props.data.sroVideoFeaturedPosts.edges) {
            setFeaturedPosts(props.data.sroVideoFeaturedPosts.edges)
        }
        if (props.data.sroVideoFaqs.edges) {
            setFaqs(props.data.sroVideoFaqs.edges)
        }

    }, [])

    return (
        <>
            <Section className='py-8' fullWidth>

                <div>
                    <HeroVideo
                        headline={sro.heroTitle}
                        subheadline={sro.heroDescription}
                        // heroImage={SroHeroImage}
                        videoId={"https://www.youtube.com/watch?v=74xnIm3tL1I"}
                        buttons={(
                            <div>
                                <LocalizedLink to={'/order-form'}>
                                    <Button chevronVariant={'white'}>
                                        {sro.actions.setup}
                                    </Button>
                                </LocalizedLink>
                                <LocalizedLink to={'#offer'}>
                                    <Button className={'ml-6 font-medium'} defaultStyle={'font-serif inline-flex items-center rounded-full'} variant={'transparent'} chevronVariant={'ink_60'}>
                                        {sro.actions.learn}
                                    </Button>
                                </LocalizedLink>
                            </div>
                        )}
                        outerContainer
                    />
                </div>
            </Section>
            <Section className={'py-20'}>
                <div className={'text-center mb-20'}>
                    <Heading id="offer" type='h3' className={'text-ink-40'}>{sro.offers.title}</Heading>
                    <Heading type={'h2'}>
                        {sro.offers.description}
                    </Heading>
                </div>
                <div className={'grid grid-cols-1 md:grid-cols-2 gap-y-32 gap-x-8 mb-12'}>
                    {sro.offers.items.map((offer, index) => (
                        <div className={'flex'}>
                            <div className={'flex w-1/5 justify-center mr-4 items-start'}>
                                <h3 className={'font-sans text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-brand-100 to-white'}>
                                    {index + 1}
                                </h3>
                            </div>
                            <div className={'w-2/3'}>
                                <Heading type="h5" className={'font-semibold'}>{offer.title}</Heading>
                                <p className={'text-ink-60 mt-2'}>{offer.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Section>
            <Section className={'py-20 bg-ink-5'}>
                <div className="grid grid-cols-2 gap-x-16">
                    <div>
                        <p className={'font-caveat text-2xl font-bold text-brand-100'}>{sro.price?.startAt ?? 1} 290 € </p>
                        <p className={'font-caveat text-xl font-bold text-ink-40 mb-3'}>+ 150 €  {sro.price?.fee ?? 1}</p>
                        <Heading type='h2' className={'mb-8'}>{sro.price.h2}</Heading>
                        <LocalizedLink to={'/order-form'}>
                            <Button variant="secondary" chevronVariant={'white'}>{sro.price.start}</Button>
                        </LocalizedLink>
                    </div>
                    <div className={'ml-8'}>
                        <img src={CostsImage} alt="" />
                    </div>
                </div>
                <div className={'border-t border-ink-20 mt-16 py-6'}>
                    <p className={'text-sm text-ink-40'}>{sro.price.disclaimer}</p>
                </div>
            </Section>
            <Section className={'py-20 bg-blue-120 text-white'}>
                <div className={'flex mb-16'}>
                    {/*<div className={'w-full lg:w-1/2 flex justify-center lg:order-last mb-10 lg:mb-0'}>*/}
                    {/*    <img src={StartYourBusiness} alt=""/>*/}
                    {/*</div>*/}
                    <div className={'w-full text-center'}>
                        <Heading type='h3'>{sro.easy.subTitle}</Heading>
                        <Heading type='h2'>{sro.easy.title}</Heading>
                    </div>
                </div>
                <div className={'grid grid-cols-1 lg:grid-cols-3 gap-24 text-white'}>
                    {sro.easy.items.map((item, index) => (
                        <div key={`sro-easy-${index}`}>
                            <Heading type='h5' className={'font-semibold'}>{item.title}</Heading>
                            <p className={'text-ink-10'}>{item.text}</p>
                        </div>
                    ))}
                </div>
                <div className={'text-center mt-16'}>
                    <LocalizedLink to={'/order-form'}>
                        <Button variant="secondary" chevronVariant={'white'}>{sro.easy.setup}</Button>
                    </LocalizedLink>
                </div>

            </Section>
            <FaqsSection items={faqs} />
            <FeaturedPostsSection posts={featuredPosts} />
            <PromoSection />
        </>
    );
};

export const query = graphql`
query SroVideoPage($locale: String) {
    sroVideoFaqs: allFaqsNode(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                data {
                    category
                    content
                    title
                }
                locale
            }
        }
    }
    sroVideoFeaturedPosts: allMdx(filter: {frontmatter: {locale: {eq: $locale}}}, sort: {order: ASC, fields: frontmatter___date}) {
        edges {
            node {
                id
                fields{
                    slug
                }
                frontmatter {
                    locale
                    title
                    date(formatString: "DD MMMM YYYY")
                    page
                    featuredimage
                }
            }
        }
    }
}`

export default SroVideoFold;
